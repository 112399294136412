.vjs-watermark-content.vjs-watermark-top-right {
    position: absolute;
    right: 10px;
    top: 20px;
}

.vjs-watermark-content.vjs-watermark-top-right img {
    height: 40px;
    opacity: 0.2
}

@media (max-width: 767px) {
    .vjs-watermark-content.vjs-watermark-top-right img {
        height: 30px;
    }   
}